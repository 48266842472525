export default {
  unauthorized_access_title: "Unauthorized access",
  you_are_not_authorized_to_access:
    "You are not authorized to access this resource.",
  network_error: "Network error",
  network_error_text:
    "Unable to connect to the server, please check your internet connection or try again later.",
  error_alert_title: "An error occurred",
  error_alert_text:
    "An unexpected error occurred during your request. Please try again later.",
  token_expired_alert_title: "Session expired",
  token_expired_alert_text: "Your session has expired. Please log in again.",
  service_error_text:
    "The service is temporarily unavailable. Please try again later.",
  Copyright: "All rights reserved",
  administration: "Administration",
  ok: "Ok",
  my_novy_account: "My Novy account",
  logout: "Logout",
  no_notifications: "No notifications",
  operational_view: "Operational view",
  sort_by: "Sort by",
  sorted_by: "Sorted by",
  projects_count_suffix: "Projects",
  select_interval: "Select an interval",
  cancel: "Cancel",
  confirm: "Confirm",
  enregistrement: "Recording",
  take_snapshot: "Freeze dashboard",
  save_dashboard_indicators_question:
    "Do you really want to freeze the indicators of this Dashboard?",
  dashboard_fige_success: "Your dashboard has been successfully frozen",
  dashboard_fige: "Frozen dashboard",
  search_placeholder: "Search",
  modification: "Modification",
  edit_dashboard_indicators_question:
    "Do you really want to modify the indicators of this dashboard?",
  dashboard_modifie_success: "Your dashboard has been successfully modified",
  annulation: "Cancellation",
  cancel_edit_dashboard_indicators_question:
    "Do you really want to cancel the modifications of this Dashboard?",
  dashboard_modifie: "Modified dashboard",
  raf: "RAF",
  date: "Date",
  risques: "Risks",
  actions: "Actions",
  en_cours_status: "In progress",
  termine_status: "Completed",
  forfait: "Fixed price",
  regie: "Time and Material",
  project_col: "Project",
  ch_vendue_col: "Sales channel",
  ch_consommee_col: "Consumed channel",
  raf_col: "RAF",
  risque_col: "Risk",
  type_col: "Type",
  status_col: "Status",
  projects_list_page_title: "Projects Overview",
  filter_all_choice: "All",
  suppression: "Deletion",
  notes: "Notes",
  delete: "Delete",
  save: "Save",
  validate: "Validate",
  yes_save: "Yes, save",
  saving: "Saving",
  yes: "Yes",
  no: "No",
  edit: "Edit",
  change_to_cancelled: "Cancel",
  project_indicators: "Project Indicators",
  data_history: "Data history",
  save_quick_edit: "Save edit",
  quick_edit: "Quick edit",
  export: "Export",
  synthese: "Summary",
  historique: "History",
  projects_list: "Project list",
  project: "Project",
  projects: "Projects",
  project_type: "Project type",
  project_types: "Project types",
  project_status: "Project statuses",
  create_success: "Creation successful",
  update_success: "Update successful",
  delete_success: "Deletion successful",
  clone_success: "Cloning successful",
  create_error: "Creation failed",
  update_error: "Update failed",
  delete_error: "Deletion failed",
  clone_error: "Cloning failed",
  ressource_in_use_error: "Ressource in use",
  no_data: "No records found",
  risque: "Risk",
  no_project_found: "No project found",
  no_project_type_found: "No project type found",
  pagination_of: "of",
  update: "Update",
  reprendre: "Resume",
  resume_live_dashboard: "Exit history",
  snapshot: "Snapshot",
  rtables: "Reference tables",
  parameters: "Parameters",
  project_risk: "Project Risk",
  project_construction: "Project Construction",
  project_perimetre: "Project Scope",
  project_planning: "Project Planning",
  project_staffing: "Project Staffing",
  action_priority: "Action Priority",
  action_status: "Action Status",
  label: "Label",
  threshold_min: "Minimum Threshold",
  level: "Level",
  description: "Description",
  name: "Name",
  value: "Value",
  add: "Add",
  confirm_delete: "Are you sure you want to delete",
  confirm_delete_type: "Are you sure you want to delete the type",
  confirm_delete_rate: "Are you sure you want to delete the rate",
  taxes: "Taxes",
  tvaRate: "VAT",
  tax_types_list: "List of tax types",
  tax_rates_list_for: "List of rates for {{typeLabel}}",
  start: "Start",
  end: "End",
  client_col: "Client",
  no_client_found: "No client found",
  include_sub_projects_switch_title: "Include sub-projects",
  client: "Client",
  clients: "Clients",
  assign_client: "Assign a client",
  notify_actions: "Send Reminders",
  language_not_yet_supported: "The language {{lang}} is not yet supported",
  parent_project: "Parent project",
  last_activity: "Last activity",
  affect_in_novy: "Assign in Novy",
  export_as_pdf: "Export as PDF",
  screen_size_not_supported_title: "Unsupported Resolution",
  screen_size_not_supported_description1: "Your screen size is not supported.",
  screen_size_not_supported_description2:
    "Please open the page on a large screen (≥960px).",
  open: "Open",
  clear: "Clear",
  close: "Close",
  variables: "Variables",
  default_value: "Default Value",
  number: "Number",
  number2: "Number",
  percent: "Percentage",
  view_as_pdf: "View as PDF",
  today: "Today",
  pdf_not_available: "PDF not available",
  add_to_favorites: "Add to favorites",
  remove_from_favorites: "Remove from favorites",
  automated_snapshot: "Automated freeze",
  page: "Page",
  first_page: "First page",
  last_page: "Last page",
  previous_page: "Previous page",
  next_page: "Next page",
  labelRowsPerPage: "Rows per page:",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  january: "january",
  february: "february",
  march: "march",
  april: "april",
  may: "may",
  june: "june",
  july: "july",
  august: "august",
  september: "september",
  october: "october",
  november: "november",
  december: "december",
  grid_view: "Grid View",
  list_view: "List View",
  view_all: "View All",
  view_favorites: "View my favorites",
  show_more: "Show more",
  all: "All",
  favorites: "Favorites",
  my_favorites: "My favorites",
  alpha_version:
    "This version is still in testing and aims to test the user experience. Further improvement may involve major functionality changes or data reset.",
  responsable: "Responsible",
  reset: "Reset",
  drag_and_drop_file: "Drag and drop your file here",
  or: "Or",
  browse: "Browse",
  kilo_byte_abbr: "KB",
  no_file_selected: "No file selected",
  processing: "Processing",
  import: "Import",
  header_row: "Header Row",
  first_data_row: "First Data Row",
  rate: "Rate",
  invalid_file_or_misconfiguration_error:
    "An error occurred. Please check your file and upload settings and try again.",
  download_template: "Download base template",
  template: "Template",
  invoicing: "Invoicing",
  invoice: "Invoice",
  invoices: "Invoices",
  risk0: "Low",
  risk1: "Medium",
  risk2: "High",
  his_date_col: "Date",
  his_periode_col: "Period",
  his_charge_vendue_col: "Sales volume",
  his_charge_consommee_col: "Consumed volume",
  his_raf_col: "RAF",
  his_risque_col: "Risk",
  his_actions_col: "Actions",
  collaborator: "Collaborator",
  no_collaborator_found: "No collaborator found",
  multiple_cloning: "Multiple cloning",
  list_invoices_to_clone: "List of invoices to clone",
  cloned_invoices_recap: "Cloned invoices recap",
  cava_mens_col: "Monthly CAVA",
  mbth_mens_euro_col: "Monthly MBth. €",
  mbth_mens_percent_col: "Monthly MBth. %",
  cava_cum_col: "Cumulative CAVA",
  mbth_cum_euro_col: "Cumulative MBth. €",
  cava_mensuel: "Monthly CAVA",
  cava_cumule: "Cumulative CAVA",
  mbth_mensuel: "Monthly MBth",
  mbth_cumule: "Cumulative MBth",
  mbth_mensuel_especes: "Monthly MBth. €",
  month: "Month",
  caht_billed: "Billed revenue",
  ca_provisional: "Provisional revenue",
  ca_awaiting_payment: "Revenue awaiting payment",
  add_invoice: "Add invoice",
  invoice_number: "Number",
  amount_excl_tax: "Amount excl. tax",
  total_excl_tax: "Total excl. tax",
  converted_amount: "Converted amount",

  unit_price: "Unit price",
  issue_date: "Issue date",
  expected_date: "Expected date",
  real_date: "Real date",
  settlement_date: "Settlement date",
  expected_settlement_date: "Payment deadline",
  status: "Status",
  download: "Download",
  all_clients: "All clients",
  new_invoice: "New invoice",
  invoice_general_info: "General information",
  invoice_label: "Invoice label",
  invoice_details: "Invoice details",
  order_reference: "Order reference",
  reference: "Reference",
  quantity: "Quantity",
  amount_incl_tax: "Amount incl. tax",
  invoice_form_title: "Invoice Editing",
  invoice_pdf_title: "Invoice PDF",
  add_order_item_label: "Add line",
  total_amount_ttc: "Total amount TTC",
  total_amount_ht: "Total amount HT",
  payed_amount_ttc: "Payed",
  payed_total_ttc: "Paid Total incl. tax",
  payments_count: "Number of payments",
  remaining_amount_ttc: "Remaining",
  montant_ca_ht_facture: "Billed HT revenue",
  montant_ca_ht_a_facturer: "To be billed HT revenue",
  montant_ca_ht_facture_en_attente: "Billed HT revenue awaiting payment",
  back_to_annual_view: "Back to annual view",
  order_items: "Order items",
  issueDate: "Issue date",
  confirm_invoice_status_change:
    "The invoice status will change from {{oldStatus}} to {{newStatus}}, do you want to continue?",
  confirm_invoice_save:
    "The invoice status is {{status}}, do you want to continue?",
  confirm_delete_invoice: "Are you sure you want to delete the invoice?",
  confirm_delete_multiple_invoices:
    "Are you sure you want to delete {{count}} invoices?",
  back_to_monthly_view: "Return to monthly view",
  invoice_audit_title: "Invoice history",
  show_audit: "Show history",
  invoice_search_placeholder: "Search invoices by number, label, reference or remaining amount incl. tax",
  no_invoice: "No invoices",
  monthly_summary: "Monthly summary",
  invoices_list: "Invoices list",
  invoicing_entity: "Invoicing entity",
  invoicing_entities: "Invoicing entities",
  address: "Address",
  postal_code: "Postal code",
  city: "City",
  country: "Country",
  code: "Code",
  vat_number: "VAT number",
  all_year: "All year",
  no_entity_found: "No entity found",
  no_tax_value: "None",
  required: "required",
  PREVISIONNELLE: "Expected",
  A_VENIR: "Upcoming",
  EMISE: "Issued",
  PARTIELLEMENT_REGLEE: "Partially settled",
  REGLEE: "Settled",
  AVOIREE: "Cancelled",
  avoir: "Avoir",
  /* BEGIN audit json */
  amountExclTax: "Amount excl. tax",
  amountInclTax: "Amount incl. tax",
  realDate: "Settlement date", // Do not remove, it is used for old audit
  settlementDate: "Settlement date",
  expectedDate: "Expected date",
  expectedSettlementDate: "Payment deadline",
  invoicingEntity: "Invoicing entity",
  triggerAction: "Trigger action",
  orderReference: "Order reference",
  invoiceType: "Invoice type",
  /* END audit json */

  invoice_different_entity: "Invoice a different entity",
  trigger_action: "Trigger action",
  invoice_trigger_action: "Invoice trigger action",

  create_non_existant_clients: "Create non-existent clients",
  create_non_existant_tax_rates: "Create non-existent tax rates",
  included_tax_types: "Included Tax Types",
  create_non_existant_invoice_entities: "Create non-existent invoice entities",
  amount: "Amount",
  invoice_fields: "Invoice fields",
  corresponding_column_in_file: "Corresponding column in file",
  corresponding_column_in_file_tooltip_text:
    "Column names are not case-sensitive",
  import_invoices_created_success:
    "Import successful. {{nbr}} invoices have been created.",
  create_invoice_title: "Create invoice",
  import_invoice_title: "Import invoices",
  show_errors: "Show errors",
  hide_errors: "Hide errors",
  import_failed_unknown_error_occured:
    "Import failed. An unknown error occurred.",
  unknown_error_occured: "An unknown error occurred.",
  unknown: "unknown",
  import_failed_one_error_occured: "Import failed. 1 error occurred.",
  import_failed_n_errors_occured: "Import failed. {{n}} errors occurred.",

  invoice_company: "Invoicing company",
  postalCode: "Postal code",
  tvaNumber: "TVA number",
  email: "Email",
  siren: "SIREN",
  iban: "IBAN",
  bic: "BIC",
  bankName: "Bank name",
  footerMessage: "Footer message",
  duplicate: "Duplicate",
  settle: 'Settle',
  company_information: "Company Information",
  invoicing_area: "Invoicing space",
  dashboard: "Dashboard",
  invoice_management: "Invoice management",
  responsable_col: "Responsible",
  digital_signature: "Digital signature",
  add_document_signature: "Add document signature",
  verify_document_signature: "Verify signature",
  preview_document: "Preview document",
  visible_stamp: "Visible stamp",
  custom_stamp: "Custom stamp",
  upload_stamp: "Upload stamp",
  sign: "Sign",
  verify: "Verify",
  stamp_settings: "Stamp settings",

  payment_time_averages: "Payment time averages",
  net_turnover: "Net turnover",
  total_net_turnover: "Total net turnover",
  evolution: "Evolution",
  top_5_clients: "Top 5 clients",
  turnover_evolution: "Turnover evolution",
  document_signature_success: "The document signature is authentic.",
  document_signature_error: "The document signature is not present or invalid.",
  document_signature_failed: "The document signature failed.",
  sign_document_success:
    "The electronic signature has been successfully added.",
  sign_document_failed: "The document signature failed.",
  stamp_size: "Stamp size (px)",
  stamp_width: "Stamp width (px)",
  stamp_height: "Stamp height (px)",

  turnover_evolution_info:
    "The evolution of turnover {{totalTurnover}} for the period from {{startMonth}} to {{endMonth}}, compared to {{prevTotalTurnOver}} for the period from {{prevStartMonth}} to {{prevEndMonth}}.",
  see_more: "See more",
  dates_filter: "Filter by dates",
  before_date_chip_title: "before {{before}}",
  after_date_chip_title: "after {{after}}",
  between_date_chip_title: "between {{after}} and {{before}}",
  late_invoices_awaiting_payment: "Late invoices awaiting payment",
  delay: "Delay (Days)",

  row: "Row",
  header: "Header",

  "file.corrupted": "Corrupted file",
  "unexpected.error": "Unexpected error",

  mustExistColumnName:
    "The column <u><i>{{first}}</i></u> does not exist in the document",
  mustBeValidColumnName:
    "The column <u><i>{{first}}</i></u> does not correspond to a valid column in the configuration",

  mustNotAlreadyExistValue: "<u><i>{{first}}</i></u> already exists",
  mustBeNullValue: "<u><i>{{first}}</i></u> must be empty",
  mustNotBeNullValue: "<u><i>{{first}}</i></u> cannot be empty",
  mustNotBeAfterValue:
    "<u><i>{{first}}</i></u> must not be after <u><i>{{first}}</i></u>",
  mustBeValidValue: "<u><i>{{first}}</i></u> is not valid",
  mustBeCorrectValue: "<u><i>{{first}}</i></u> is incorrect",
  mustBeExplicitValue:
    "<u><i>{{first}}</i></u> is ambiguous and must be explicit",
  mustBeOfTypeValue:
    "<u><i>{{first}}</i></u> must be of type <i>{{second}}</i>",
  issueDateBeforeLastEmittedInvoiceIssueDate:
    "The issue date must not be earlier than the issue date of the last emitted invoice (Last emitted invoice number {{number}} and issue date {{issueDate}})",

  my_settings: "My settings",
  receive_invoices_issue_reminders: "Receive invoice issue reminders",
  receive_invoices_settlement_reminders: "Receive invoice settlement reminders",
  enable_issue_reminders: "Enable issue reminders",
  disable_issue_reminders: "Disable issue reminders",
  enable_settlement_reminders: "Enable settlement reminders",
  disable_settlement_reminders: "Disable settlement reminders",
  issue_reminders_enabled: "Issue reminders enabled",
  issue_reminders_disabled: "Issue reminders disabled",
  settlement_reminders_enabled: "Settlement reminders enabled",
  settlement_reminders_disabled: "Settlement reminders disabled",
  invoice_reminder_days: "Invoice reminder days",
  invoice_reminders: "Invoice reminders",
  payment_delay: "Payment delay",
  languages: "Languages",
  invoicing_config: "Invoicing configuration",
  go_to_invoicing_area: "Go to invoicing space",
  late_invoices_awaiting_issuing: "Late invoices awaiting issuing",
  send_reminders_at: "Send reminders at",
  reminder_time_config_info:
    "The modification will take effect after the next send",
  issue_reminder_config_text_1: "Send issue reminders starting",
  issue_reminder_config_text_2: "days before the expected date",
  settlement_reminder_config_text_1: "Send settlement reminders starting",
  settlement_reminder_config_text_2: "days before the expected settlement date",
  payment_delay_config_text_1: "Set payment delay to",
  payment_delay_config_text_2: "days after the issue date",
  invoice_generation_language_text: "Invoice generation language",
  payments: "Payments",
  invoicePayments: "Payment",
  add_a_payment: "Add a payment",
  payments_list: "Payments list",
  select_an_invoice: "Select an invoice",
  select_a_group: "Select a group",
  group: "Group",
  no_invoice_found: "No invoice found",
  loading: "Loading",
  bankingInfoBloc: "Free writing bloc",

  chart_legend_net_turnover: "Net invoiced turnover",
  chart_legend_pending_turnover: "Pending payment turnover",
  chart_legend_expected_turnover: "Expected turnover",
  chart_legend_collected_turnover: "Collected turnover",
  clients_classified_by_turnover: "Clients classified by turnover",
  show_all: "Show all",
  of_which_pending_payment: "{{content}} pending payment",
  total_expected_payment: "{{content}} expected payment",
  my_invoices: "My invoices",
  my_payments: "My payments",
  search: "Search",

  session_expired_title: "Session expired",
  session_expired_details:
    "You need to refresh the page to refresh your session, any unsaved input will be lost.",
  refresh: "Refresh",
  page_not_found: "Page Not Found",
  page_not_found_description:
    "The page you are requesting seems to be not found.",

  global_audit: "Global audit",
  rangeStart: "Start",
  rangeFinish: "Finish",
  user: "User",
  action: "Action",
  details: "Details",
  action_details: "Action details",
  action_CREATE: "Create",
  action_CREATE_ATTACHMENT: "Create attachment: {{name}}",
  action_READ: "Read",
  action_READ_ATTACHMENT: "Read attachment: {{name}}",
  action_UPDATE: "Update",
  action_UPDATE_ATTACHMENT: "Update attachment: {{name}}",
  action_DELETE: "Delete",
  action_DELETE_ATTACHMENT: "Delete attachment: {{name}}",
  action_EXPORT_INVOICE_PDF: "Export invoice pdf",
  action_EXPORT_INVOICE_EXCEL: "Export invoice excel",
  action_EXPORT_INVOICES_EXCEL: "Export invoices excel",
  action_EXPORT_MONTH_INVOICING_EXCEL: "Export summary of invoices excel",
  action_EXPORT_PAYMENTS_EXCEL: "Export payments excel",
  action_EXPORT_MONTH_PAYMENTS_EXCEL: "Export summary of payments excel",
  action_SENT_ISSUE_EMAIL: "Sent issue email",
  action_SENT_REMINDER_EMAIL: "Sent reminder email",
  last_hour: "Last hour",
  last_day: "Last 24 hours",
  last_week: "Last week",
  last_month: "Last month",
  custom: "Custom",
  ATTACHMENT_NOT_FOUND: "Attachment not found",

  collaborator_not_allowed_to_manage_invoice:
    "{{collaboratorName}} is not allowed to manage invoices for the selected client or project",
  access_control: "Access Control",
  access_control_by_client: "Access control by client",
  confirm_delete_access_control:
    "Are you sure you want to delete the access control entry for {{collaboratorName}}?",
  available_clients: "Available clients",
  assigned_clients: "Assigned clients",
  available_projects: "Available projects",
  assigned_projects: "Assigned projects",
  no_collaborator_is_selected: "No collaborator is selected",

  attachments: "Attachments",
  attachment: "Attachment",
  invoiceAttachmentName: "Name",
  invoiceAttachmentType: "Type",
  invoiceAttachmentFileType: "File type",
  add_attachment: "Add attachment",
  invoice_attachment_types: "Attachment types",
  view: "View",
  no_status_found: "No status found",

  validation_errors: "Validation errors",
  invoice_form_data_required: "The form is empty",
  invoice_client_required: "The client is required",
  invoice_label_required: "The label is required",
  invoice_expected_date_required: "The expected date is required",
  invoice_expected_settlement_date_required:
    "The payment deadline is required",
  invoice_expected_date_should_be_before_expected_settlement_date:
    "The expected date must be on or before the payment deadline",
  invoice_issue_date_should_be_before_expected_settlement_date:
    "The payment deadline must not be earlier than the issue date",
  invoice_status_required: "The status is required",
  invoice_issue_date_required_for_emise_status:
    "The issue date is required for the selected status",
  invoice_issue_date_required_for_reglee_status:
    "The issue date is required for the selected status",
  invoice_settlement_date_required_for_reglee_status:
    "The settlement date is required for the selected status",
  invoice_issue_date_required_for_avoiree_status:
    "The issue date is required for the selected status",
  invoice_settlement_date_required_for_avoiree_status:
    "The settlement date is required for the selected status",
  invoice_issue_date_should_not_be_after_today:
    "The issue date must not be after the current date",
  invoice_settlement_date_should_not_be_after_seven_days:
    "The settlement date must be on or before 7 days after the current date",
  invoice_issue_date_should_be_before_settlement_date:
    "The issue date must be on or before the settlement date",
  invoice_order_item_description_required:
    "The order item description is required",
  invoice_order_item_description_too_long:
    "Maximum character limit exceeded for the order item description. Please limit your input to 255 characters",
  invoice_avoir_date_required_for_avoiree_status:
    "The avoir date is required for the selected status",
  invoice_order_reference_required_for_digital_client: "The order reference is required for digital clients",

  confirm_delete_attachment:
    "Are you sure you want to delete the attachment {{attachmentName}}?",
  attachment_preview: "Attachment Preview",
  client_already_assigned: "Client {{name}} already assigned",
  parent_project_already_assigned: "Parent project {{name}} already assigned",

  format_address: "Format address",
  format_address_tooltip_1: "- Entry: New line in a new paragraph",
  format_address_tooltip_2: "- Shift+Enter: New line in the same paragraph.",
  return: "Return",
  groups: "Groups",
  invoicingClients: "Invoicing clients",
  projectClients: "Project clients",
  none: "None",

  settlement_title_one: "Settlement of invoice no. {{invoiceNumber}}",
  settlement_title_multiple: "Settlement of {{count}} invoices",
  apply_expected_date_to_all_invoices:
    "Apply the expected date to all invoices",
  invoice_currency: "Invoice currency",
  currency: "Currency",
  currencies: "Currencies",
  exchangeRate: "Exchange rate",
  no_currency_found: "No currency found",
  confirm_delete_currency: "Are you sure you want to delete",
  type: "Type",
  None: "None",
  Monthly: "Monthly",
  Annual: "Annual",
  recurrence: "Recurrence",
  reccurence_type: "Reccurence type",
  create_recurrent_invoices: "Create reccuring invoices",
  recurrence_limit_date: "Recurrence limit date",
  recurring_invoices_recap: "Recap of created recurrent invoices",
  send_invoice: "Send Invoice N°{{invoiceNumber}}",
  sent_invoice: "Invoice N°{{invoiceNumber}} sent on {{mailDeliveryDate}}, by {{mailSender}}",
  object: "Object",
  message: "Message",
  issue_email: "Issue email",
  recipients: "Recipients",
  send_invoice_reminder_for_invoices: "Send a reminder for invoices:",
  send_as_mail: "Send",
  last_reminder: "Last reminder",
  relance: "Send reminder",
  send_reminder_for_selected_invoice: "Send a reminder for the selected invoice",
  send_reminder_for_client_invoices: "Send a reminder for all the client's invoices",
  show: "Show",
  hide: "Hide",
  email_sent: "Email sent",
  error_sending_email: "Error sending email",
  add_emails_for: "At least one email for {{recipients}} must be added",
  cannot_bulk_settle_invoices_with_more_than_one_different_currency_than_default: "Cannot bulk settle invoices with more than one different currency than the default currency",
  amount_tolerance_for_grouped_payment_search: "Amount tolerance for grouped payment search",
  amount_larger_than_remaining_amount: "Amount larger than remaining amount",
  operation_failed: "Operation failed",
  invoice_payment_invoice_search_placeholder: "Search invoices by number, label or remaining amount incl. tax",
  sent_at: "Sent at",
  send: 'Send',
  reminder_email: "Reminder email",
  issue_email_sent: "Issue email sent",
  issue: "Issue",
  issue_invoice: "Issue the invoice",
  avoir_of_invoice: "Invoice credit for invoice N° {{avoireeNumber}}",
  avoir_number: "Avoir N° {{avoireNumber}}",
  issue_mail_template_object: "Invoice No. {{number}} - {{label}}",
  issue_mail_template_body: "<p>Hello,</p><p>Please find our invoice No. {{number}}.</p><p>Thank you for your attention and prompt processing of this invoice.</p><p>Best regards,</p>",
  issue_mail_template_body_with_order: "<p>Hello,</p><p>Please find our invoice No. {{number}} related to your order No. {{orderNumber}}.</p><p>Thank you for your attention and prompt processing of this invoice.</p><p>Best regards,</p>",
  reminder_mail_template_object: "Reminder for Invoice No. {{number}} - {{label}}",
  reminder_mail_template_body: "<p>Hello,</p><p>Unless there is an error or omission on our part, we have not yet received payment for invoice No. {{number}} issued on {{issueDate}} with a total amount of {{amountTTC}}.</p><p>We kindly request you to review this information and, if necessary, make the payment for this invoice as soon as possible.</p><p>We are available for any further information you may need.</p><p>Best regards,</p>",
  remaining_to_pay: "Remaining to pay",
  add_cc_emails: "Add cc emails",
  add_emails: "Add emails",
  emails: "Emails",
  cc_emails: "Cc emails",
  cciEmail: "Bcc email",
  cc: "Cc",
  bcc: "Bcc",
  year: "Year",
  export_criteria: "Export Criteria",
  responsableIds: "Responsible IDs",
  clientIds: "Client IDs",
  invoicingEntitiesIds: "Invoicing Entity IDs",
  projectIds: "Project IDs",
  projectTypes: "Project Types",
  afterExpectedDate: "After Expected Date",
  beforeExpectedDate: "Before Expected Date",
  afterExpectedSettlementDate: "After Expected Settlement Date",
  beforeExpectedSettlementDate: "Before Expected Settlement Date",
  afterIssueDate: "After Issue Date",
  beforeIssueDate: "Before Issue Date",
  afterSettlementDate: "After Settlement Date",
  beforeSettlementDate: "Before Settlement Date",
  NOT_FOUND: "Not found",
  change_status_to_cancelled: "Change to status to cancelled",
  confirmation: "Confirmation",
  siret: "Siret",
  select_an_invoicing_perimetre: "Select an invoicing perimetre",
  MISE_A_DISPOSITION: "Available",
  SUSPENDUE: "Suspended",
  REJETEE: "Rejected",
  MANDATEE: "Mandated",
  MISE_EN_PAIEMENT: "Ready for Payment",
  COMPTABILISEE: "Accounted",
  MISE_A_DISPOSITION_COMPTABLE: "Accounting Available",
  A_RECYCLER: "To Be Recycled",
  SERVICE_FAIT: "Service Completed",
  DEPOSEE: "Deposited",
  A_VALIDER_1: "To validate",
  confirm_issue_to_chorus: "Confirm issue to Chorus",
  siret_for_client_must_be_defined: "the client's SIRET must be defined by the administrator",
  invoice_already_issued_to_chorus: "Invoice already issued to Chorus",
  sent: "Sent",
  electronicStatus: "Electronic status",
  purchase_order: "Purchase order number",
  contract_number: "Contract number",
  electronic_invoices: "Electronic invoices",
  invoicing_perimetre: "Invoicing perimetre",
  invoice_issued_to_chorus_successfully: "Invoice issued to Chorus successfully",
  A1_FACTURE_FOURNISSEUR: "A1: Deposit by a supplier of an invoice",
  A2_FACTURE_FOURNISSEUR_DEJA_PAYEE: "A2: Deposit of an already paid invoice (e.g., purchase card)",
  A9_FACTURE_SOUSTRAITANT: "A9: Deposit of an invoice by a subcontractor",
  A12_FACTURE_COTRAITANT: "A12: Deposit of an invoice by a co-contractor",
  validator: "Validator",
  invoice_issued_to_chorus_succesfully: "Invoice issued to chorus succesfully",
  error_submitting_digital_invoice: "Error submitting digital invoice",
  payment_service_number: "Payment service number",
  invoice_type: "Invoice type",
  no_type_found: "No type found",
  assign_to_all_clients: "Assign to all clients"
};
