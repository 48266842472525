import { Box, Card, CardActions, CardContent, Checkbox, Grid, IconButton, InputAdornment, Radio, TextField, Tooltip, Button, MenuItem, Typography, Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import ClearIcon from '@material-ui/icons/Clear';
import { get } from 'lodash-es';
import { Autocomplete } from '@material-ui/lab';
import { adjustDefaultValues } from '../../../../utils/converters';

var timeout = 0;

function RtCreateCard({ rtable, setCreate, instances, setInstances, resetInstances, instance, setInstance }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [errors, setErrors] = useState(rtable.columns.reduce((obj, col) => ({ ...obj, [col.id]: !col.optional && col.type !== "boolean" && col.type !== "radio" }), {}));
    const [disabled, setDisabled] = useState(true);
    const rtGlobalList = useSelector(({ rt }) => rt);

    const save = () => {
        if (rtable.columns.some(col => col.type === "radio")) {
            instances.forEach(inst => {
                dispatch(rtable.put(inst.id, inst)).then(() => {
                });
            });
        }
        dispatch(rtable.post(adjustDefaultValues(rtable, instance)));
        setCreate(false);
    };

    const onRadioChange = (e, col) => {
        setInstance({ ...instance, [col.apiName]: e.target.checked });
        if (e.target.checked) {
            setInstances([...instances].map(i => ({ ...i, [col.apiName]: false })));
        }
    };

    const onCancelClick = () => {
        resetInstances();
        setCreate(false);
    };

    const onTextChange = (col, value) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            setInstance({ ...instance, [col.apiName]: value });
            if (!col.optional) {
                setErrors({ ...errors, [col.id]: value === "" });
            }
            if(col.validated) {
                setErrors({ ...errors, [col.id]: col.optional && (value === "") ? false : !col.validated(value)});
            }
        }, 250);
    };

    const hasCollapsedColumns = () => {
        return rtable.columns.some(col => col.collapse);
    };

    const renderColumn = (col) => {
        switch (col.type) {
            case "boolean":
                return (
                    <Checkbox
                        defaultChecked={false}
                        color="primary"
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: e.target.checked });
                        }}
                    />
                );
            case "radio":
                return (
                    <Radio
                        checked={instance[col.apiName]}
                        color="primary"
                        onChange={e => onRadioChange(e, col)}
                    />
                );
            case "number":
                return (
                    <TextField
                        type="number"
                        placeholder={t(col.name)}
                        variant="outlined"
                        size="small"
                        fullWidth 
                        onChange={e => {
                            setInstance({ ...instance, [col.apiName]: col.reverseTransform ? col.reverseTransform(e.target.value) : e.target.value });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" || col.validated ? !col.validated(e.target.value) : false });
                        }}
                        {...(col.inputProps
                            ? {
                                inputProps: col.inputProps
                            }
                            : {})
                        }
                        {
                            ...(
                                col.endAdornment
                                    ? {
                                        InputProps: { endAdornment: <InputAdornment classes={{ root: classes.endAdornment }} position="end">{col.endAdornment}</InputAdornment> }
                                    }
                                    : {}
                            )
                        }
                    />
                );
            case "select":
                return (
                    <TextField
                        select
                        defaultValue=""
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={e => {
                            setInstance({ ...instance, [col.apiBase]: rtGlobalList[col.config.storeName].find(x => x.id === e.target.value) || null });
                            !col.optional && setErrors({ ...errors, [col.id]: !e.target.value });
                        }}
                        style={{ width: "100%" }}
                    >
                        {col.optional && <MenuItem value="">{t("none")}</MenuItem>}
                        {rtGlobalList[col.config.storeName].map((item, idx) => <MenuItem key={idx} value={item.id} style={{ width: "100%" }}>{get(item, col.config.apiName)}</MenuItem>)}
                    </TextField>
                );
            case "textList":
                return (    
                    <Autocomplete
                        options={[]}
                        freeSolo
                        multiple
                        renderTags={(value, props) =>
                            value?.map((option, index) => (
                                <Chip 
                                    size="small"
                                    label={option}
                                    style={{backgroundColor: col.validated && col.validated(option) ? "" : "#ff000054"}}
                                    {...props({ index })} 
                                />
                            ))
                        }
                        renderInput={
                            (params) => 
                                <TextField
                                    error={errors[col.id]}
                                    variant="outlined" 
                                    label={t("add_" + col.name)} {...params} 
                                />}
                        value={instance[col.apiName] || []}
                        variant="outlined"
                        size="small"
                        placeholder={t(col.name)}
                        fullWidth
                        {...(col.inputProps
                            ? {
                                inputProps: col.inputProps
                            }
                            : {})}
                        onChange={(e, val) => {
                            setInstance({ ...instance, [col.apiName]: val });
                            !col.optional && setErrors({ ...errors, [col.id]: e.target.value === "" });
                            if(col.validated){
                                let hasInvalidItem = false;
                                for(const item of val){
                                    if(!col.validated(item)){
                                        hasInvalidItem = true;
                                        break;
                                    }
                                }
                                setErrors({ ...errors, [col.id]: hasInvalidItem});
                            }
                        }}
                    />
                );
            default:
                return (
                    <TextField
                        type="text"
                        placeholder={t(col.name)}
                        defaultValue=""
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => onTextChange(col, e.target.value)}
                        error={col.showError && errors[col.id]}
                    />
                );
        }
    };

    useEffect(() => {
        setDisabled(!Object.values(errors).every(e => e === false));
    }, [errors]);

    useEffect(() => {
        return () => {
            setInstance(null);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        instance &&
        <Card
            className={classes.root}
            elevation={1}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10}>
                    <CardContent className={classes.content} style={{ paddingBottom: "16px" }}>
                        <Grid container>
                            {
                                rtable.columns.filter(col => !col.collapse).map(col =>
                                    <Grid container item key={col.id} xs={col.width} alignItems="center" justifyContent="center">
                                        <Grid item xs={col.fullWidth ? 12 : undefined}>
                                            <Box mr={1} textAlign="center">
                                                {renderColumn(col)}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            }{
                                hasCollapsedColumns() &&
                                <Grid item xs={12} className={classes.collapse} >
                                    {
                                        rtable.columns.filter(col => col.collapse).map(col =>
                                            <Grid key={col.id} container alignItems="center" spacing={2}>
                                                <Grid item xs={2}>
                                                    <Typography variant="overline" className={classes.colName}>
                                                        {t(col.name)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {renderColumn(col)}
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={2}>
                    <CardActions>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={save}
                                    disabled={disabled}
                                >
                                    {t('save')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t('cancel')} placement="top">
                                    <IconButton onClick={onCancelClick}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
}

export default RtCreateCard;