export default {
  unauthorized_access_title: "Unberechtigter Zugriff",
  you_are_not_authorized_to_access:
    "Sie sind nicht berechtigt, auf diese Ressource zuzugreifen.",
  network_error: "Netzwerkfehler",
  network_error_text:
    "Verbindung zum Server nicht möglich. Bitte überprüfen Sie Ihre Internetverbindung oder versuchen Sie es später erneut.",
  error_alert_title: "Ein Fehler ist aufgetreten",
  error_alert_text:
    "Bei Ihrer Anfrage ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  token_expired_alert_title: "Sitzung abgelaufen",
  token_expired_alert_text:
    "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
  service_error_text:
    "Der Dienst ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut.",
  Copyright: "Alle Rechte vorbehalten",
  administration: "Verwaltung",
  ok: "OK",
  my_novy_account: "Mein Novy-Konto",
  logout: "Abmelden",
  no_notifications: "Keine Benachrichtigungen",
  operational_view: "Betriebsansicht",
  sort_by: "Sortieren nach",
  sorted_by: "Sortiert nach",
  projects_count_suffix: "Projekte",
  select_interval: "Interval auswählen",
  cancel: "Abbrechen",
  confirm: "Bestätigen",
  enregistrement: "Aufnahme",
  take_snapshot: "Dashboard einfrieren",
  save_dashboard_indicators_question:
    "Möchten Sie die Indikatoren dieses Dashboards wirklich einfrieren?",
  dashboard_fige_success: "Ihr Dashboard wurde erfolgreich eingefroren",
  dashboard_fige: "Eingefrorenes Dashboard",
  search_placeholder: "Suche",
  modification: "Änderung",
  edit_dashboard_indicators_question:
    "Möchten Sie die Indikatoren dieses Dashboards wirklich ändern?",
  dashboard_modifie_success: "Ihr Dashboard wurde erfolgreich geändert",
  annulation: "Stornierung",
  cancel_edit_dashboard_indicators_question:
    "Möchten Sie die Änderungen dieses Dashboards wirklich abbrechen?",
  dashboard_modifie: "Geändertes Dashboard",
  raf: "RAF",
  date: "Datum",
  risques: "Risiken",
  actions: "Aktionen",
  en_cours_status: "In Bearbeitung",
  termine_status: "Abgeschlossen",
  forfait: "Pauschalpreis",
  regie: "Zeit und Material",
  project_col: "Projekt",
  ch_vendue_col: "Verkaufskanal",
  ch_consommee_col: "Verbrauchter Kanal",
  raf_col: "RAF",
  risque_col: "Risiko",
  type_col: "Typ",
  status_col: "Status",
  projects_list_page_title: "Projektübersicht",
  filter_all_choice: "Alle",
  suppression: "Löschung",
  notes: "Notizen",
  delete: "Löschen",
  save: "Speichern",
  validate: "Bestätigen",
  yes_save: "Ja, speichern",
  saving: "Speichern",
  yes: "Ja",
  no: "Nein",
  change_to_cancelled: "Andern",
  edit: "Bearbeiten",
  project_indicators: "Projektindikatoren",
  data_history: "Datenverlauf",
  save_quick_edit: "Änderungen speichern",
  quick_edit: "Schnellbearbeitung",
  export: "Exportieren",
  synthese: "Zusammenfassung",
  historique: "Verlauf",
  projects_list: "Projektliste",
  project: "Projekt",
  projects: "Projekte",
  project_type: "Projekttyp",
  project_types: "Projekttypen",
  project_status: "Projektstatus",
  create_success: "Erstellung erfolgreich",
  update_success: "Aktualisierung erfolgreich",
  delete_success: "Löschung erfolgreich",
  clone_success: "Klonen erfolgreich",
  create_error: "Erstellung fehlgeschlagen",
  update_error: "Aktualisierung fehlgeschlagen",
  delete_error: "Löschung fehlgeschlagen",
  clone_error: "Klonen fehlgeschlagen",
  no_data: "Keine Datensätze gefunden",
  risque: "Risiko",
  no_project_found: "Kein Projekt gefunden",
  no_project_type_found: "Kein Projekttyp gefunden",
  pagination_of: "von",
  update: "Aktualisieren",
  reprendre: "Fortsetzen",
  resume_live_dashboard: "Verlauf verlassen",
  snapshot: "Momentaufnahme",
  rtables: "Referenztabelle",
  parameters: "Parameter",
  project_risk: "Projektrisiko",
  project_construction: "Projektkonstruktion",
  project_perimetre: "Projektumfang",
  project_planning: "Projektplanung",
  project_staffing: "Projektbesetzung",
  action_priority: "Aktionspriorität",
  action_status: "Aktionsstatus",
  label: "Beschriftung",
  threshold_min: "Minimalschwelle",
  level: "Stufe",
  description: "Beschreibung",
  name: "Name",
  value: "Wert",
  add: "Hinzufügen",
  confirm_delete: "Möchten Sie wirklich löschen",
  confirm_delete_type: "Möchten Sie den Typ wirklich löschen",
  confirm_delete_rate: "Möchten Sie den Satz wirklich löschen",
  taxes: "Steuern",
  tvaRate: "MwSt.",
  tax_types_list: "Liste der Steuertypen",
  tax_rates_list_for: "Liste der Sätze für {{typeLabel}}",
  start: "Start",
  end: "Ende",
  client_col: "Kunde",
  no_client_found: "Kein Kunde gefunden",
  include_sub_projects_switch_title: "Unterprojekte einschließen",
  client: "Kunde",
  clients: "Kunden",
  assign_client: "Kunde zuweisen",
  notify_actions: "Erinnerungen senden",
  language_not_yet_supported:
    "Die Sprache {{lang}} wird noch nicht unterstützt",
  parent_project: "Übergeordnetes Projekt",
  last_activity: "Letzte Aktivität",
  affect_in_novy: "In Novy zuweisen",
  export_as_pdf: "Als PDF exportieren",
  screen_size_not_supported_title: "Nicht unterstützte Auflösung",
  screen_size_not_supported_description1:
    "Ihre Bildschirmgröße wird nicht unterstützt.",
  screen_size_not_supported_description2:
    "Bitte öffnen Sie die Seite auf einem großen Bildschirm (≥960px).",
  open: "Öffnen",
  clear: "Löschen",
  close: "Schließen",
  variables: "Variablen",
  default_value: "Standardwert",
  number: "Zahl",
  number2: "Zahl",
  percent: "Prozent",
  view_as_pdf: "Als PDF anzeigen",
  today: "Heute",
  pdf_not_available: "PDF nicht verfügbar",
  add_to_favorites: "Zu Favoriten hinzufügen",
  remove_from_favorites: "Aus Favoriten entfernen",
  automated_snapshot: "Automatische Einfrierung",
  page: "Seite",
  first_page: "Erste Seite",
  last_page: "Letzte Seite",
  previous_page: "Vorherige Seite",
  next_page: "Nächste Seite",
  labelRowsPerPage: "Zeilen pro Seite:",
  January: "Januar",
  February: "Februar",
  March: "März",
  April: "April",
  May: "Mai",
  June: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Dezember",
  january: "januar",
  february: "februar",
  march: "märz",
  april: "april",
  may: "mai",
  june: "juni",
  july: "juli",
  august: "august",
  september: "september",
  october: "oktober",
  november: "november",
  december: "dezember",
  grid_view: "Rasteransicht",
  list_view: "Listenansicht",
  view_all: "Alle anzeigen",
  view_favorites: "Meine Favoriten anzeigen",
  show_more: "Mehr anzeigen",
  all: "Alle",
  favorites: "Favoriten",
  my_favorites: "Meine Favoriten",
  alpha_version:
    "Diese Version befindet sich noch im Test und soll die Benutzererfahrung testen. Weitere Verbesserungen können umfangreiche Funktionsänderungen oder Datenrücksetzungen beinhalten.",
  responsable: "Verantwortlicher",
  reset: "Zurücksetzen",
  drag_and_drop_file: "Ziehen Sie Ihre Datei hierhin",
  or: "Oder",
  browse: "Durchsuchen",
  kilo_byte_abbr: "KB",
  no_file_selected: "Keine Datei ausgewählt",
  processing: "Verarbeitung",
  import: "Importieren",
  header_row: "Kopfzeile",
  first_data_row: "Erste Datenspalte",
  rate: "Satz",
  invalid_file_or_misconfiguration_error:
    "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Datei und Hochladeeinstellungen und versuchen Sie es erneut.",
  download_template: "Basisvorlage herunterladen",
  template: "Vorlage",
  invoicing: "Rechnungsstellung",
  invoice: "Rechnung",
  invoices: "Rechnungen",
  risk0: "Niedrig",
  risk1: "Mittel",
  risk2: "Hoch",
  his_date_col: "Datum",
  his_periode_col: "Zeitraum",
  his_charge_vendue_col: "Verkaufsvolumen",
  his_charge_consommee_col: "Verbrauchtes Volumen",
  his_raf_col: "RAF",
  his_risque_col: "Risiko",
  his_actions_col: "Aktionen",
  collaborator: "Mitarbeiter",
  no_collaborator_found: "Kein Mitarbeiter gefunden",
  multiple_cloning: "Mehrfachklonierung",
  list_invoices_to_clone: "Liste der zu klonenden Rechnungen",
  cloned_invoices_recap: "Zusammenfassung der geklonten Rechnungen",
  cava_mens_col: "Monatlicher CAVA",
  mbth_mens_euro_col: "Monatlicher MBth. €",
  mbth_mens_percent_col: "Monatlicher MBth. %",
  cava_cum_col: "Kumulativer CAVA",
  mbth_cum_euro_col: "Kumulativer MBth. €",
  cava_mensuel: "Monatlicher CAVA",
  cava_cumule: "Kumulativer CAVA",
  mbth_mensuel: "Monatlicher MBth",
  mbth_cumule: "Kumulativer MBth",
  mbth_mensuel_especes: "Monatlicher MBth. €",
  month: "Monat",
  caht_billed: "Abgerechneter Umsatz HT",
  ca_provisional: "Vorläufiger Umsatz",
  ca_awaiting_payment: "Umsatz zur Zahlung ausstehend",
  add_invoice: "Rechnung hinzufügen",
  invoice_number: "Nummer",
  amount_excl_tax: "Betrag ohne MwSt.",
  total_excl_tax: "Gesamtsumme ohne MwSt.",
  converted_amount: "Konvertierter Betrag",
  unit_price: "Stückpreis",
  issue_date: "Ausstellungsdatum",
  expected_date: "Voraussichtliches Datum",
  real_date: "Reales Datum",
  settlement_date: "Zahlungsdatum",
  expected_settlement_date: "Erwartetes Zahlungsdatum",
  status: "Status",
  download: "Herunterladen",
  all_clients: "Alle Kunden",
  new_invoice: "Neue Rechnung",
  invoice_general_info: "Allgemeine Informationen",
  invoice_label: "Rechnungsbezeichnung",
  invoice_details: "Rechnungsdetails",
  order_reference: "Bestellreferenz",
  reference: "Referenz",
  quantity: "Menge",
  amount_incl_tax: "Betrag inkl. MwSt.",
  invoice_form_title: "Rechnung bearbeiten",
  invoice_pdf_title: "Rechnung PDF",
  add_order_item_label: "Zeile hinzufügen",
  total_amount_ttc: "Gesamtbetrag TTC",
  total_amount_ht: "Gesamtbetrag HT",
  payed_amount_ttc: "Bezahlter Betrag TTC",
  payed_total_ttc: "Gesamtbezahlt inkl. Steuer",
  payments_count: "Anzahl der Zahlungen",
  remaining_amount_ttc: "Verbleibender Betrag TTC",
  montant_ca_ht_facture: "Abgerechneter HT-Umsatz",
  montant_ca_ht_a_facturer: "Noch abzurechnender HT-Umsatz",
  montant_ca_ht_facture_en_attente:
    "Abgerechneter HT-Umsatz zur Zahlung ausstehend",
  back_to_annual_view: "Zurück zur Jahresansicht",
  order_items: "Bestellpositionen",
  issueDate: "Ausstellungsdatum",
  confirm_invoice_status_change:
    "Der Rechnungsstatus wird von {{oldStatus}} auf {{newStatus}} geändert. Möchten Sie fortfahren?",
  confirm_invoice_save:
    "Der Rechnungsstatus lautet {{status}}. Möchten Sie fortfahren?",
  confirm_delete_invoice: "Möchten Sie die Rechnung wirklich löschen?",
  confirm_delete_multiple_invoices:
    "Möchten Sie wirklich {{count}} Rechnungen löschen?",
  back_to_monthly_view: "Zurück zur monatlichen Ansicht",
  invoice_audit_title: "Rechnungsverlauf",
  show_audit: "Verlauf anzeigen",
  invoice_search_placeholder:
    "Suchen Sie Rechnungen nach Nummer, Beschriftung, Referenz oder Restbetrag inkl. Rechnungen. Steuer",
  no_invoice: "Keine Rechnungen",
  monthly_summary: "Monatliche Zusammenfassung",
  invoices_list: "Liste der Rechnungen",
  invoicing_entity: "Rechnungsstellungseinheit",
  invoicing_entities: "Rechnungsstellungseinheiten",
  address: "Adresse",
  postal_code: "Postleitzahl",
  city: "Stadt",
  country: "Land",
  code: "Code",
  vat_number: "USt-IdNr.",
  all_year: "Das ganze Jahr",
  no_entity_found: "Keine Einheit gefunden",
  no_tax_value: "Keine",
  required: "erforderlich",
  PREVISIONNELLE: "Erwartet",
  A_VENIR: "Bevorstehend",
  EMISE: "Ausgestellt",
  PARTIELLEMENT_REGLEE: "Teilweise beglichen",
  REGLEE: "Beglichen",
  AVOIREE: "Storniert",
  avoir: "Gutschrift",
  /* BEGIN audit json */
  amountExclTax: "Betrag ohne MwSt.",
  amountInclTax: "Betrag inkl. MwSt.",
  realDate: "Zahlungsdatum", // Do not remove, it is used for old audit
  settlementDate: "Zahlungsdatum",
  expectedDate: "Voraussichtliches Datum",
  expectedSettlementDate: "Erwartetes Zahlungsdatum",
  invoicingEntity: "Rechnungsstellungseinheit",
  triggerAction: "Auslösende Aktion",
  orderReference: "Bestellreferenz",
  invoiceType: "Rechnungstyp",
  /* END audit json */
  invoice_different_entity: "Rechnung an eine andere Einheit",
  trigger_action: "Auslösende Aktion",
  invoice_trigger_action: "Rechnungsauslösende Aktion",

  create_non_existant_clients: "Nicht vorhandene Kunden erstellen",
  create_non_existant_tax_rates: "Nicht vorhandene Steuersätze erstellen",
  included_tax_types: "Eingeschlossene Steuerarten",
  create_non_existant_invoice_entities:
    "Nicht vorhandene Rechnungseinheiten erstellen",
  amount: "Betrag",
  invoice_fields: "Rechnungsfelder",
  corresponding_column_in_file: "Entsprechende Spalte in der Datei",
  corresponding_column_in_file_tooltip_text:
    "Spaltennamen sind nicht case-sensitive",
  import_invoices_created_success:
    "Import erfolgreich. {{nbr}} Rechnungen wurden erstellt.",
  create_invoice_title: "Rechnung erstellen",
  import_invoice_title: "Rechnungen importieren",
  show_errors: "Fehler anzeigen",
  hide_errors: "Fehler ausblenden",
  import_failed_unknown_error_occured:
    "Import fehlgeschlagen. Ein unbekannter Fehler ist aufgetreten.",
  unknown_error_occured: "Ein unbekannter Fehler ist aufgetreten.",
  unknown: "unbekannt",
  import_failed_one_error_occured:
    "Import fehlgeschlagen. 1 Fehler ist aufgetreten.",
  import_failed_n_errors_occured:
    "Import fehlgeschlagen. {{n}} Fehler sind aufgetreten.",

  invoice_company: "Rechnungsstellendes Unternehmen",
  postalCode: "Postleitzahl",
  tvaNumber: "TVA-Nummer",
  email: "E-Mail",
  siren: "SIREN",
  iban: "IBAN",
  bic: "BIC",
  bankName: "Bankname",
  footerMessage: "Fußzeilen-Nachricht",
  duplicate: "Duplizieren",
  settle: "Abwickeln",
  company_information: "Unternehmensinformationen",
  invoicing_area: "Abrechnungsbereich",
  dashboard: "Dashboard",
  invoice_management: "Rechnungsmanagement",
  responsable_col: "Verantwortlich",
  digital_signature: "Digitale Signatur",
  add_document_signature: "Dokumentensignatur hinzufügen",
  verify_document_signature: "Signatur überprüfen",
  preview_document: "Dokumentvorschau",
  visible_stamp: "Sichtbarer Stempel",
  custom_stamp: "Individueller Stempel",
  upload_stamp: "Stempel hochladen",
  sign: "Unterschreiben",
  verify: "Verifizieren",
  stamp_settings: "Stempel-Einstellungen",

  payment_time_averages: "Durchschnittliche Zahlungszeit",
  net_turnover: "Nettoumsatz",
  total_net_turnover: "Gesamtnettoumsatz",
  evolution: "Entwicklung",
  top_5_clients: "Top 5 Kunden",
  turnover_evolution: "Umsatzentwicklung",
  document_signature_success: "Die Dokumentensignatur ist authentisch.",
  document_signature_error: "Die Dokumentensignatur fehlt oder ist ungültig.",
  document_signature_failed: "Die Dokumentensignatur ist fehlgeschlagen.",
  sign_document_success:
    "Die elektronische Signatur wurde erfolgreich hinzugefügt.",
  sign_document_failed: "Die Dokumentensignatur ist fehlgeschlagen.",
  stamp_size: "Stempelgröße (px)",
  stamp_width: "Stempelbreite (px)",
  stamp_height: "Stempelhöhe (px)",

  turnover_evolution_info:
    "Die Entwicklung des Umsatzes von {{totalTurnover}} für den Zeitraum von {{startMonth}} bis {{endMonth}} im Vergleich zu {{prevTotalTurnOver}} für den Zeitraum von {{prevStartMonth}} bis {{prevEndMonth}}",
  see_more: "Mehr anzeigen",
  dates_filter: "Nach Datum filtern",
  before_date_chip_title: "vor dem {{before}}",
  after_date_chip_title: "nach dem {{after}}",
  between_date_chip_title: "zwischen {{after}} und {{before}}",
  late_invoices_awaiting_payment: "Überfällige Rechnungen warten auf Zahlung",
  delay: "Verzögerung (Tage)",

  row: "Zeile",
  header: "Kopfzeile",

  "file.corrupted": "Korrupte Datei",
  "unexpected.error": "Unerwarteter Fehler",

  mustExistColumnName:
    "Die Spalte <u><i>{{first}}</i></u> existiert nicht im Dokument",
  mustBeValidColumnName:
    "Die Spalte <u><i>{{first}}</i></u> entspricht keiner gültigen Spalte in der Konfiguration",

  mustNotAlreadyExistValue: "<u><i>{{first}}</i></u> existiert bereits",
  mustBeNullValue: "<u><i>{{first}}</i></u> muss leer sein",
  mustNotBeNullValue: "<u><i>{{first}}</i></u> darf nicht leer sein",
  mustNotBeAfterValue:
    "<u><i>{{first}}</i></u> darf nicht nach <u><i>{{first}}</i></u> liegen",
  mustBeValidValue: "<u><i>{{first}}</i></u> ist nicht gültig",
  mustBeCorrectValue: "<u><i>{{first}}</i></u> ist falsch",
  mustBeExplicitValue:
    "<u><i>{{first}}</i></u> ist mehrdeutig und muss explizit sein",
  mustBeOfTypeValue:
    "<u><i>{{first}}</i></u> muss vom Typ <i>{{second}}</i> sein",
  issueDateBeforeLastEmittedInvoiceIssueDate:
    "Das Ausstellungsdatum darf nicht vor dem Ausstellungsdatum der letzten ausgestellten Rechnung liegen (Letzte ausgestellte Rechnungsnummer {{number}} und Ausstellungsdatum {{issueDate}})",
  my_settings: "Meine Einstellungen",
  receive_invoices_issue_reminders: "Rechnungsausgabenerinnerungen erhalten",
  receive_invoices_settlement_reminders:
    "Rechnungsbegleichungserinnerungen erhalten",
  enable_issue_reminders: "Erinnerungen für Ausgabe aktivieren",
  disable_issue_reminders: "Erinnerungen für Ausgabe deaktivieren",
  enable_settlement_reminders: "Erinnerungen für Begleichung aktivieren",
  disable_settlement_reminders: "Erinnerungen für Begleichung deaktivieren",
  issue_reminders_enabled: "Erinnerungen für Ausgabe aktiviert",
  issue_reminders_disabled: "Erinnerungen für Ausgabe deaktiviert",
  settlement_reminders_enabled: "Erinnerungen für Begleichung aktiviert",
  settlement_reminders_disabled: "Erinnerungen für Begleichung deaktiviert",
  invoice_reminder_days: "Rechnungserinnerungstage",
  invoice_reminders: "Rechnungserinnerungen",
  payment_delay: "Zahlungsverzug",
  languages: "Sprachen",
  invoicing_config: "Rechnungskonfiguration",
  go_to_invoicing_area: "Zur Abrechnungsoberfläche gehen",
  late_invoices_awaiting_issuing: "Überfällige Rechnungen warten auf Ausgabe",
  send_reminders_at: "Erinnerungen senden um",
  reminder_time_config_info:
    "Die Änderung wird nach dem nächsten Senden wirksam",
  issue_reminder_config_text_1: "Sende Ausgabeerinnerungen ab",
  issue_reminder_config_text_2: "Tagen vor dem erwarteten Datum",
  settlement_reminder_config_text_1: "Sende Begleichungserinnerungen ab",
  settlement_reminder_config_text_2:
    "Tagen vor dem erwarteten Begleichungsdatum",
  payment_delay_config_text_1: "Setze Zahlungsverzug auf",
  select_an_invoice: "Wählen Sie eine Rechnung aus",
  select_a_group: "Wählen Sie eine Gruppe aus",
  group: "Gruppe",
  payment_delay_config_text_2: "Tage nach dem Ausgabedatum",
  invoice_generation_language_text: "Rechnungserstellungssprache",
  payments: "Zahlungen",
  invoicePayments: "Zahlung",
  add_a_payment: "Zahlung hinzufügen",
  payments_list: "Zahlungsliste",
  loading: "Wird geladen",
  bankingInfoBloc: "Freier eingabeblock",

  chart_legend_net_turnover: "Netto-Rechnungsumsatz",
  chart_legend_pending_turnover: "Ausstehender Zahlungsumsatz",
  chart_legend_expected_turnover: "Erwarteter Umsatz",
  chart_legend_collected_turnover: "Gesammelt Umsatz",
  clients_classified_by_turnover: "Kunden nach Umsatz klassifiziert",
  show_all: "Alle anzeigen",
  of_which_pending_payment: "{{content}} ausstehende Zahlung",
  total_expected_payment: "{{content}} erwartete Zahlung",
  my_invoices: "Meine Rechnungen",
  my_payments: "Meine Zahlungen",
  search: "Suche",

  session_expired_title: "Sitzung abgelaufen",
  session_expired_details:
    "Sie müssen die Seite aktualisieren, um Ihre Sitzung zu erneuern. Alle nicht gespeicherten Eingaben gehen verloren.",
  refresh: "Aktualisieren",
  page_not_found: "Seite nicht gefunden",
  page_not_found_description:
    "Die von Ihnen angeforderte Seite scheint nicht gefunden zu werden.",

  global_audit: "Globale Überprüfung",
  rangeStart: "Start",
  rangeFinish: "Ende",
  user: "Benutzer",
  action: "Aktion",
  details: "Einzelheiten",
  action_details: "Aktionseinzelheiten",
  action_CREATE: "Erstellen",
  action_CREATE_ATTACHMENT: "Anhang erstellen: {{name}}",
  action_READ: "Lesen",
  action_READ_ATTACHMENT: "Anhang lesen: {{name}}",
  action_UPDATE: "Aktualisieren",
  action_UPDATE_ATTACHMENT: "Anhang aktualisieren: {{name}}",
  action_DELETE: "Löschen",
  action_DELETE_ATTACHMENT: "Anhang löschen: {{name}}",
  action_EXPORT_INVOICE_PDF: "Export Rechnung PDF",
  action_EXPORT_INVOICE_EXCEL: "Export Rechnung Excel",
  action_EXPORT_INVOICES_EXCEL: "Export Rechnungen Excel",
  action_EXPORT_MONTH_INVOICING_EXCEL: "Export Zusammenfassung der Rechnungen Excel",
  action_EXPORT_PAYMENTS_EXCEL: "Export Zahlungen Excel",
  action_EXPORT_MONTH_PAYMENTS_EXCEL: "Export Zusammenfassung der Zahlungen Excel",
  action_SENT_ISSUE_EMAIL: "E-Mail zur Übertragung senden",
  action_SENT_REMINDER_EMAIL: "E-Mail zur Erinnerung senden",
  last_hour: "Letzte Stunde",
  last_day: "Letzte 24 Stunden",
  last_week: "Letzte Woche",
  last_month: "Letzter Monat",
  custom: "Benutzerdefiniert",
  ATTACHMENT_NOT_FOUND: "Anhang nicht gefunden",

  collaborator_not_allowed_to_manage_invoice:
    "{{collaboratorName}} darf keine Rechnungen für den ausgewählten Kunden oder das ausgewählte Projekt verwalten",
  access_control: "Zugriffskontrolle",
  access_control_by_client: "Zugriffskontrolle nach Kunde",
  confirm_delete_access_control:
    "Sind Sie sicher, dass Sie den Zugriffskontrolle-Eintrag für {{collaboratorName}} löschen möchten?",
  available_clients: "Verfügbare Kunden",
  assigned_clients: "Zugewiesene Kunden",
  available_projects: "Verfügbare Projekte",
  assigned_projects: "Zugewiesene Projekte",
  no_collaborator_is_selected: "Kein Mitarbeiter ist ausgewählt",

  attachments: "Anhänge",
  attachment: "Anhang",
  invoiceAttachmentName: "Name",
  invoiceAttachmentType: "Typ",
  invoiceAttachmentFileType: "Dateityp",
  add_attachment: "Anhang hinzufügen",
  invoice_attachment_types: "Anhangstypen",
  view: "Anzeigen",
  no_status_found: "Kein Status gefunden",

  validation_errors: "Validierungsfehler",
  invoice_form_data_required: "Das Formular ist leer",
  invoice_client_required: "Der Kunde ist erforderlich",
  invoice_label_required: "Das Etikett ist erforderlich",
  invoice_expected_date_required: "Das erwartete Datum ist erforderlich",
  invoice_expected_settlement_date_required:
    "Das erwartete Begleichungsdatum ist erforderlich",
  invoice_expected_date_should_be_before_expected_settlement_date:
    "Das erwartete Datum muss am oder vor dem erwarteten Begleichungsdatum liegen",
  invoice_issue_date_should_be_before_expected_settlement_date:
    "Das erwartete Begleichungsdatum darf nicht früher als das Ausgabedatum sein",
  invoice_status_required: "Der Status ist erforderlich",
  invoice_issue_date_required_for_emise_status:
    "Das Ausgabedatum ist für den ausgewählten Status erforderlich",
  invoice_issue_date_required_for_reglee_status:
    "Das Ausgabedatum ist für den ausgewählten Status erforderlich",
  invoice_settlement_date_required_for_reglee_status:
    "Das Begleichungsdatum ist für den ausgewählten Status erforderlich",
  invoice_issue_date_required_for_avoiree_status:
    "Das Ausgabedatum ist für den ausgewählten Status erforderlich",
  invoice_settlement_date_required_for_avoiree_status:
    "Das Begleichungsdatum ist für den ausgewählten Status erforderlich",
  invoice_issue_date_should_not_be_after_today:
    "Das Ausgabedatum ist nicht nach dem aktuellen Datum liegen",
  invoice_settlement_date_should_not_be_after_seven_days:
    "Das Begleichungsdatum muss am oder vor 7 Tagen nach dem aktuellen Datum liegen",
  invoice_issue_date_should_be_before_settlement_date:
    "Das Ausgabedatum muss am oder vor dem Begleichungsdatum liegen",
  invoice_order_item_description_required:
    "Die Beschreibung des Auftragsprodukts ist erforderlich",
  invoice_order_item_description_too_long:
    "Maximale Zeichenanzahl für die Artikelbeschreibung in der Bestellung überschritten. Bitte beschränken Sie Ihre Eingabe auf 255 Zeichen",
  invoice_avoir_date_required_for_avoiree_status:
    "The avoir date is required for the selected status",
  invoice_order_reference_required_for_digital_client: "De bestelreferentie is vereist voor digitale klanten",

  confirm_delete_attachment:
    "Sind Sie sicher, dass Sie den Anhang {{attachmentName}} löschen möchten?",
  client_already_assigned: "Kunde {{name}} ist bereits zugewiesen",
  parent_project_already_assigned:
    "Übergeordnetes Projekt {{name}} ist bereits zugewiesen",

  format_address: "Adresse formatieren",
  format_address_tooltip_1: "- Eingabe: Neue Zeile in einem neuen Absatz",
  format_address_tooltip_2: "- Umschalt+Enter: Neue Zeile im selben Absatz.",
  return: "Zurückkehren",
  groups: "Gruppen",
  invoicingClients: "Abrechnungskunden",
  projectClients: "Projektkunden",
  none: "Keine",

  settlement_title_one: "Begleichung der Rechnung Nr. {{invoiceNumber}}",
  settlement_title_multiple: "Begleichung von {{count}} Rechnungen",
  apply_expected_date_to_all_invoices:
    "Das erwartete Datum auf alle Rechnungen anwenden",
  invoice_currency: "Rechnungswährung",
  currency: "Währung",
  currencies: "Währungen",
  exchangeRate: "Wechselkurs",
  no_currency_found: "Keine Währung gefunden",
  confirm_delete_currency:
    "Sind Sie sicher, dass Sie die Währung löschen möchten?",
  type: "Typ",
  None: "Keine",
  Monthly: "Monatlich",
  Annual: "Jährlich",
  recurrence: "Wiederauftreten",
  reccurence_type: "Wiederholungstyp",
  create_recurrent_invoices: "Erstellen Sie wiederkehrende Rechnungen",
  recurrence_limit_date: "Datum der Wiederholungsbeschränkung",
  recurring_invoices_recap: "Zusammenfassung der erstellten wiederkehrenden Rechnungen",
  send_invoice: "Rechnung senden N°{{invoiceNumber}}",
  sent_invoice: "Faktur N°{{invoiceNumber}} gesandter vom {{mailDeliveryDate}}, von {{mailSender}}",
  issue_email: "E-Mail ausstellen",
  object: "Objekt",
  message: "Nachricht",
  recipients: "Empfänger",
  send_invoice_reminder_for_invoices: "Senden sie eine erinnerung für die rechnungen",
  send_as_mail: "Schicken",
  last_reminder: "Zuletzt erinnerung",
  relance: "Erinnern",
  send_reminder_for_selected_invoice: "Die ausgewählte Rechnung erinnern",
  send_reminder_for_client_invoices: "Alle Rechnungen des Kunden erinnern",
  show: "Anzeigen",
  hide: "Verbergen",
  email_sent: "E-Mail gesendet",
  error_sending_email: "Fehler beim Senden der E-Mail",
  add_emails_for: "Mindestens eine E-Mail für {{recipients}} muss hinzugefügt werden",
  cannot_bulk_settle_invoices_with_more_than_one_different_currency_than_default: "Der Massenausgleich von Rechnungen mit mehr als einer anderen Währung als der Standardwährung ist nicht möglich",
  amount_tolerance_for_grouped_payment_search: "Betragstoleranz für die Suche nach gruppierten Zahlungen",
  amount_larger_than_remaining_amount: "Betrag größer als Restbetrag",
  operation_failed: "Operation fehlgeschlagen",
  invoice_payment_invoice_search_placeholder: "Suchen Sie Rechnungen nach Nummer, Beschriftung oder Restbetrag inkl. Rechnungen. Steuer",
  sent_at: "Gesendet an",
  send: "Schicken",
  reminder_email: "Erinnerungsmail",
  issue_email_sent: "Ausgabe-E-Mail gesendet",
  issue: "Ausstellen",
  issue_invoice: "Die Rechnung ausstellen",
  avoir_of_invoice: "BGutschriftsrechnung für Rechnung N° {{avoireeNumber}}",
  avoir_number: "Avoir N° {{avoireNumber}}",
  issue_mail_template_object: "Rechnung Nr. {{number}} - {{label}}",
  issue_mail_template_body: "<p>Hallo,</p><p>Bitte finden Sie unsere Rechnung Nr. {{number}}.</p><p>Vielen Dank für Ihre Aufmerksamkeit und die prompte Bearbeitung dieser Rechnung.</p><p>Mit freundlichen Grüßen,</p>",
  issue_mail_template_body_with_order: "<p>Hallo,</p><p>Bitte finden Sie unsere Rechnung Nr. {{number}} in Bezug auf Ihre Bestellung Nr.{{orderNumber}}.</p><p>Vielen Dank für Ihre Aufmerksamkeit und die prompte Bearbeitung dieser Rechnung.</p><p>Mit freundlichen Grüßen,</p>",
  reminder_mail_template_object: "Zahlungserinnerung Rechnung Nr. {{number}} - {{label}}",
  reminder_mail_template_body: "<p>Hallo,</p><p>Sofern kein Fehler oder Versäumnis unsererseits vorliegt, haben wir die Zahlung für die Rechnung Nr. {{number}}, ausgestellt am {{issueDate}} mit einem Gesamtbetrag von {{amountTTC}}, noch nicht erhalten.</p><p>Wir bitten Sie höflich, diese Informationen zu überprüfen und gegebenenfalls die Zahlung für diese Rechnung so bald wie möglich vorzunehmen.</p><p>Wir stehen Ihnen für weitere Informationen gerne zur Verfügung.</p><p>Mit freundlichen Grüßen,</p>",
  remaining_to_pay: "Restzahlung",
  avoir_date: "Avoir Datum",
  add_cc_emails: "CC-E-Mails hinzufügen",
  add_emails: "E-Mails hinzufügen",
  emails: "Emails",
  ccEmail: "Cc email",
  cc_emails: "Cc emails",
  cciEmail: "Bcc email",
  cc: "Cc",
  bcc: "Bcc",
  year: "Jahr",
  export_criteria: "Exportkriterien",
  responsableIds: "Verantwortliche IDs",
  clientIds: "Kunden IDs",
  invoicingEntitiesIds: "Rechnungsstellungs-IDs",
  projectIds: "Projekt-IDs",
  projectTypes: "Projektarten",
  afterExpectedDate: "Nach dem erwarteten Datum",
  beforeExpectedDate: "Vor dem erwarteten Datum",
  afterExpectedSettlementDate: "Nach dem erwarteten Zahlungsdatum",
  beforeExpectedSettlementDate: "Vor dem erwarteten Zahlungsdatum",
  afterIssueDate: "Nach dem Ausgabedatum",
  beforeIssueDate: "Vor dem Ausgabedatum",
  afterSettlementDate: "Nach dem Zahlungsdatum",
  beforeSettlementDate: "Vor dem Zahlungsdatum",
  NOT_FOUND: "nicht gefunden",
  change_status_to_cancelled: "Status auf storniert ändern",
  confirmation: "Bestätigung",
  siret: "Siret",
  select_an_invoicing_perimetre: "Wählen Sie einen Rechnungsumfang",
  MISE_A_DISPOSITION: "Bereitgestellt",
  SUSPENDUE: "Ausgesetzt",
  REJETEE: "Abgelehnt",
  MANDATEE: "Beauftragt",
  MISE_EN_PAIEMENT: "Zahlungsbereit",
  COMPTABILISEE: "Verbucht",
  MISE_A_DISPOSITION_COMPTABLE: "Buchungsbereitstellung",
  A_RECYCLER: "Zum Recycling",
  SERVICE_FAIT: "Dienst erbracht",
  DEPOSEE: "Deposierte",
  A_VALIDER_1: "Zu bestätigen",
  confirm_issue_to_chorus: "die Übertragung an Chorus bestätigen",
  siret_for_client_must_be_defined: "the client's SIRET must be defined",
  invoice_already_issued_to_chorus: "Rechnung bereits an Chorus ausgestellt",
  sent: "Gesendet",
  electronicStatus: "Elektronischer Status",
  purchase_order_number: "Bestellnummer",
  contract_number: "Vertragsnummer",
  electronic_invoices: "Elektronisch rechnungen",
  invoicing_perimetre: "Rechnungserstellungsumfang",
  invoice_issued_to_chorus_successfully: "Rechnung erfolgreich an Chorus ausgestellt",
  A1_FACTURE_FOURNISSEUR: "A1: Einzahlung durch einen Lieferanten einer Rechnung",
  A2_FACTURE_FOURNISSEUR_DEJA_PAYEE: "A2: Einzahlung einer bereits bezahlten Rechnung (z. B. Einkaufskarte)",
  A9_FACTURE_SOUSTRAITANT: "A9: Einzahlung einer Rechnung durch einen Subunternehmer",
  A12_FACTURE_COTRAITANT: "A12: Einzahlung einer Rechnung durch einen Mitunternehmer",
  validator: "Validator",
  invoice_issued_to_chorus_succesfully: "Rechnung erfolgreich an Chorus ausgegeben",
  error_submitting_digital_invoice: "Fehler beim Einreichen der digitalen Rechnung",
  payment_service_number: "Zahlungsdienstnummer",
  invoice_type: "Rechnungstyp",
  no_type_found: "Kein Typ gefunden",
  assign_to_all_clients: "Allen Kunden zuweisen"
};
