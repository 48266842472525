import {
    SET_ALL_RT_PROJECT_STATUS,
    SET_ALL_RT_PROJECT_TYPE,
    SET_ALL_PROJECTS,
    SET_ALL_RT_INVOICING_CLIENT, 
    SET_ALL_RT_INVOICING_ENTITY, 
    SET_ALL_RT_INVOICING_STATUS, 
    SET_ALL_RT_TAX_TYPE,
    SET_ALL_RT_INVOICE_ATTACHMENT_TYPE,
    SET_ALL_RT_PROJECT_CLIENT,
    SET_ALL_RT_TAX_TYPE_RATES,
    SET_ALL_RT_CURRENCIES,
    SET_ALL_RT_INVOICE_TYPE
} from "../../constants";

const initialState = {
    projectStatus: [],
    projectType: [],
    projects: [],
    projectClients: [],
    invoiceStatus: [],
    taxTypes: [],
    taxTypeRates: [],
    invoicingEntities: [],
    invoicingClients: [],
    currencies: [],
    invoiceAttachmentTypes: [],
    invoiceTypes: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_RT_PROJECT_STATUS:
            return setAllProjectStatus(state, action.payload);
        case SET_ALL_RT_PROJECT_TYPE:
            return setAllProjectType(state, action.payload);
        case SET_ALL_PROJECTS:
            return setAllProjects(state, action.payload);
        case SET_ALL_RT_PROJECT_CLIENT:
            return setProjectClients(state, action.payload);
        case SET_ALL_RT_INVOICING_STATUS:
            return setInvoiceStatus(state, action.payload);
        case SET_ALL_RT_TAX_TYPE:
            return setInvoiceTaxTypes(state, action.payload);
        case SET_ALL_RT_TAX_TYPE_RATES:
            return setInvoiceTaxTypeRates(state, action.payload);
        case SET_ALL_RT_INVOICING_ENTITY:
            return setInvoicingEntities(state, action.payload);
        case SET_ALL_RT_INVOICING_CLIENT:
            return setInvoicingClients(state, action.payload);
        case SET_ALL_RT_INVOICE_ATTACHMENT_TYPE:
            return setAllInvoiceAttachmentType(state, action.payload);
        case SET_ALL_RT_CURRENCIES:
            return setAllRtCurrencies(state, action.payload);
        case SET_ALL_RT_INVOICE_TYPE:
            return setAllRtInvoiceType(state, action.payload);
        default:
            return state;
    }
};

const setAllRtCurrencies = (state, payload) => {
    return {
        ...state,
        currencies: payload,
    };
}

const setAllProjectStatus = (state, payload) => {
    return {
        ...state,
        projectStatus: payload,
    };
};

const setAllProjectType = (state, payload) => {
    return {
        ...state,
        projectType: payload,
    };
};

const setAllProjects = (state, payload) => {
    return {
        ...state,
        projects: payload,
    };
};

const setProjectClients = (state, data) => {
    return {
        ...state,
        projectClients: data
    };
};

const setInvoiceStatus= (state, data) => {
    return {
        ...state,
        invoiceStatus: data
    };
};

const setInvoiceTaxTypes = (state, data) => {
    return {
        ...state,
        taxTypes: data
    };
};

const setInvoiceTaxTypeRates = (state, data) => {
    return {
        ...state,
        taxTypeRates: data
    };
};

const setInvoicingEntities = (state, data) => {
    return {
        ...state,
        invoicingEntities: data
    };
};

const setInvoicingClients = (state, data) => {
    return {
        ...state,
        invoicingClients: data
    };
};

const setAllInvoiceAttachmentType = (state, data) => {
    return {
        ...state,
        invoiceAttachmentTypes: data
    };
};

const setAllRtInvoiceType = (state, data) => {
    return {
        ...state,
        invoiceTypes: data
    };
};
